<script>


/**
 * Deposit Edit component
 */


import Choices from "choices.js";
import {autoComplete} from '@/api/misc'
import { financial } from '@/api/misc'

import {required} from "vuelidate/lib/validators";



export default {

    emits: ['confirm', 'cancel'],


    props: ['agent_list'],

    components: {
        
    },

    validations: {
       amount : {required},
       gst    : {required},
    },

    

    data() {
        return {
            fees: [],
            fee_list_choice:{},
            fee_code : '',
            agent_id : '', 
            description: '',
            amount : '',
            gst    : '',
        }
    },

    methods: {
        f(v, d=2) {
            return financial(v, d)
        },

        formSubmit() {
            let fee_item = {
                fee_code   : this.fee_code,
                agent_id   : this.agent_id,
                fee_reason : this.description,
                amount     : this.amount,
                gst        : this.gst,
            }
            this.$v.$touch();
            if (this.$v.$error == false) {     
                this.$emit('confirm', fee_item)
            }
        },

        onSearchedFee(e) {
          
            let query = {
                types : ['SEARCH_FEE'],
                str : e.detail.value
            }
            autoComplete(query).then((res) =>{
                this.fees = []
                res.data.map((f) =>{
                    this.fees.push({
                        label : `${f.name}`,
                        value : f.code,
                        fee : f
                    })
                })
               
                this.fee_list_choice.clearChoices()
                this.fee_list_choice.setChoices(this.fees)
                this.fee_list_choice.showDropdown()
                
            })
           
        },

    },

    created() {
        
      
    },

    mounted() {
        this.fee_list_choice = new Choices('#fee_list_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        let agents = [{
            label : "",
            value : "",
            agent : {}
        }]
        if (this.agent_list) {
            this.agent_list.map((a) => {
                agents.push({
                    label : `${a.first_name} ${a.last_name}`,
                    value : a.native_id,
                    agent : a
                })
            })
        }
        new Choices('#charge_agent_choice', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
            choices: agents
        })
        
    },

    watch: {
        amount(new_val) {
            this.gst = this.f(Number(new_val) * 0.05)
        } 
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                 <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="charge_agent_choice" class="form-label font-size-13 text-muted">Agent Name</label>
                            <select  class="form-control"  data-trigger
                                v-model="agent_id"
                                name="charge_agent_choice"
                                id="charge_agent_choice"
                            >
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="mb-3">
                            <label for="fee_list_choice" class="form-label font-size-13 text-muted"  >Fee Type</label>
                            <select
                                class="form-control"
                                data-trigger
                                v-model="fee_code"
                                name="fee_list_choice"
                                id="fee_list_choice"
                               
                                @search="onSearchedFee"
                                >
                                
                                <option value=""></option>
                            </select>
                           
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="mb-3">
                            <label for="amount" class="form-label font-size-13 text-muted"  >Amount</label>
                            <input class="form-control"  id="amount" v-model="amount" :class="{  'is-invalid': $v.amount.$error,  }"/>
                            <div v-if="$v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="mb-3">
                            <label for="amount" class="form-label font-size-13 text-muted"  >Gst</label>
                            <input class="form-control"  id="gst" v-model="gst" :class="{  'is-invalid': $v.gst.$error,  }"/>
                            <div v-if="$v.gst.$error" class="invalid-feedback">
                                <span v-if="!$v.gst.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                 <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="charge_agent_choice" class="form-label font-size-13 text-muted">Description</label>
                            <input class="form-control"  v-model="description"/>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>