<script>


/**
 * Deposit Edit component
 */

 import {
  required,
} from "vuelidate/lib/validators";




export default {

    emits: ['confirm', 'cancel'],

    props: ['file_name'],
    components: {
        //flatPickr
    },

    validations: {
        new_file_name : {required}
    },

    

    data() {
        return {
            submitted : false,
            new_file_name : ''
        }
    },

    methods: {
        formSubmit() {
        
            this.submitted = true
            this.$v.$touch();
            if (this.$v.$error == false) { 
                this.$emit('confirm', this.new_file_name)
            }
        },

    },

    created() {
      this.new_file_name = this.file_name
    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">New File Name</label>
                            <input id="validationCustom01" v-model="new_file_name" type="text"
                                class="form-control" placeholder="Please Input File Name"  :class="{
                                    'is-invalid': submitted && $v.new_file_name.$error,
                                }" />
                            <div v-if="submitted && $v.new_file_name.$error" class="invalid-feedback">
                                <span v-if="!$v.new_file_name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>