<script>


import {
  required,
} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {

    emits: ['confirm', 'cancel'],

    props: ['commsissions'],
    components: {
        flatPickr,
        vueDropzone: vue2Dropzone,
    },

    validations: {
        closedFile: {
            close_number :{required},
            close_date   : {required}
        }
    },

    

    data() {
        return {
            submitted : false,
            
            closedFile : {
                close_number : '',
                close_date   : '',
                auto_pay     : true,
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },
        }
    },

    methods: {
        formSubmit() {
           this.submitted = true
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                this.$emit('confirm', this.closedFile)
            }
        },


        fileUploaded(ev, resp) {
            console.log("uploaded", ev.upload, ev, resp)
            if (resp[0].errCode == 200) {
                this.closedFil.save_route      = resp[0].response.url
                this.closedFil.save_route_key  = resp[0].response.key
                this.closedFil.file_name       = resp[0].response.filename
                this.closedFil.size            = resp[0].response.size
                this.closedFil.type            = resp[0].response.type
                
            } 
        },
    },

    created() {
      
    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label"
                                >Register Number</label
                            >
                            <input
                                class="form-control"
                                id="formFileSm"
                                v-model="closedFile.close_number"
                                type="text"
                                :class="{
                                        'is-invalid': submitted && $v.closedFile.close_number.$error,
                                    }"
                            />
                            <div v-if="submitted && $v.closedFile.close_number.$error" class="invalid-feedback">
                                <span v-if="!$v.closedFile.close_number.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label  class="form-label" >Close Date</label  >
                            <flat-pickr
                                class="form-control"  v-model="closedFile.close_date"  type="text"
                                :class="{
                                        'is-invalid': submitted && $v.closedFile.close_date.$error,
                                    }"
                            />
                            <div v-if="submitted && $v.closedFile.close_date.$error" class="invalid-feedback">
                                <span v-if="!$v.closedFile.close_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                   
                </div>
                <!-- end row-->
                <!-- div class="row" >
                    <div class="col-xl-12 mb-3">
                        Auto Pay Commission 
                        <input type="checkbox" class="form-checkbox" v-model="closedFile.auto_pay" />
                    </div>
                </div -->
                <div class="table-responsive" style="min-height: 20vh;">
                    <div class="table align-middle table-nowrap">
                        <b-table :items="commsissions" :fields="['Agent', 'Commission','GST']" responsive="sm"   class="table-check">
                            <template #cell(Agent)="data">
                                {{ data.item.full_name }}
                            </template>
                            <template #cell(Commission)="data">
                                ${{ data.item.commission.toLocaleString() }}
                            </template>
                            <template #cell(GST)="data">
                                ${{ data.item.gst.toLocaleString() }}
                            </template>
                        </b-table>
                    </div>
                </div>
                 <!-- end table responsive -->

                <div class="row d-none" >
                    <div class="col-xl-12 mb-3">
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"
                            @vdropzone-success="fileUploaded">
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop scaned cheque and receipt to here.</h4>
                            </div>
                        </vue-dropzone>
                    </div>

                </div>
                <!-- end row-->




                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit" :disabled="submitted">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>