<script>


/**
 * File Upload  component
 */


import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Choices from "choices.js";

import {getDealApi} from '@/api/deal/index'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],

    components: {
        vueDropzone: vue2Dropzone,
    },


    data() {

        return {
            submitted: false,
            files: [],
            folder_items: [],

            current_folder: -1,
            choice_obj: {},
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },
            
        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            this.$emit('confirm', this.files)

        },

        folder_changed_changed() {

           
        },

        fileUploaded(ev, resp) {
            console.log("uploaded" , ev.upload, ev, resp)
            if (resp[0].errCode == 200) {
                
                let folderEle = this.folder_items.find(fi => fi.folder.id == this.current_folder)
                let data = {
                    folder_id : folderEle.folder.id,
                    folder_name : folderEle.folder.name,
                    save_route : resp[0].response.url,
                    deal_id : this.deal.order_deal_id,
                }

                getDealApi().add_file(data).then(res => {
                    if (res.errCode == 0) {
                        this.files.push({
                            deal_id : this.deal.order_deal_id,
                            folder_id : folderEle.folder.id,
                            save_route : resp[0].response.url,
                            folder_name :folderEle.name,
                            file_name : res.data.file_name,
                            id : res.data.id
                        })
                    } else {
                        this.$alertify.error("Upload File Faile "+res.errCode);
                    }
                })
                
            }
        },

    },

    created() {
        
    },

    mounted() {

        this.deal.folders.map((f) => {
            this.folder_items.push({
                label : f.name,
                value : f.id,
                folder :f
            })
        })
        this.choice_obj = new Choices("#choices-listing-file", {
            searchEnabled: true,
            removeItemButton: false,
            
        })
        this.choice_obj.setChoices(this.folder_items)

    }

};
</script>

<template>
    <div class="row">
        <div class=" row  justify-content-between d-flex align-items-center">
            <div class="col-md-12">
                <label>Select Folder:</label>
                <select class="form-control" name="choices-listing-file" id="choices-listing-file" data-trigger
                    @change="folder_changed_changed" v-model="current_folder"
                    placeholder="select refuse reason categorys">
                </select>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 card-body">
                <div class="col-lg-12">
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="fileUploaded">
                        <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Drop File here or click to upload File.</h4>
                        </div>
                    </vue-dropzone>
                </div>
            </div>
           
        </div>
       
        <div class="row">
            <div class="d-flex  gap-2  col-md-2 mx-auto">
            </div>

            <div class="d-flex  gap-2  col-md-6 mx-auto">
                <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                <b-button variant="primary" type="button" @click="formSubmit">Save</b-button>
            </div>

        </div>
        <!-- end row-->
    </div>
    <!-- end card -->
</template>