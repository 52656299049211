<script>


/**
 * Deposit Info TAB component
 */




// import pdf from '@teckel/vue-pdf'
// import PdfPreview from '@/components/pdf-preview.vue'
import FolderTree from "@/components/folder-tree"
import FileUploadDialog from './file_upload_dialog.vue'
import FileNameUpdateDialog from './file_name_update_dialog.vue'
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import DownloadFilesDialog from './download_files_dialog.vue'
import SendMailDialog from './send_mail_dialog.vue'
import {getDealApi} from '@/api/deal/index'

export default {

  emits: ['confirm', 'cancel'],

  props: {
    deal : {
      type: Object,
      default: () => ({
         
      })
    }
  },
  components: {
    FolderTree,
    FileUploadDialog,
    FileNameUpdateDialog,
    DownloadFilesDialog,
    VuePdfApp,
    SendMailDialog,
    ckeditor: CKEditor.component
  },

  data() {

    return {

      disable_release_btn : true,
      editor: ClassicEditor,
      selected_files: [],
      current_file : {},
      file_name : '',
      preview_file_url : '',
      pdf_previe_config: {
       
        secondaryToolbar : false,
        sidebar : false,

        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          },
          
          toolbarViewerRight : {
            presentationMode: false,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
          },
        }
      },

      content : ''
    }

  },


  computed: {
      btn_disable_flag() {
          return  this.deal.order_state == 'CLOSED' ||  this.deal.order_state == 'COLLAPSED'
      }
  },

  methods: {

    fileDownload(f) {
     
      this.preview_file_url = '/adm/deal/file/deal_file_download?url='+f.save_route
    },

    fileEdit(action, f) {
      if (action == 'Edit') {
        this.open_file_update_dialog(f)
      } else if (action =='Download') {
         window.open(f.save_route, '_blank');
      } else if (action =='Delete') {
        this.$alertify.confirmWithTitle(
          "Confirmation",
          `Are you sure to Delete  ${f.file_name}?`,
          () => {
            getDealApi().delete_file({file_id : f.id, deal_id : this.deal.order_deal_id}).then((res) => {
              if (res.errCode == 0) {
                this.$alertify.message("File was Deleted ");
                //remove from list

                this.deal.folder_group.map(g => {
                   g.folders.map(fo => {
                    fo.files = fo.files.filter(ff => ff.id != f.id)
                  })
                  
                })
              
              } else {
                this.$alertify.error("Failed to Delete " + res.msg);
              }
            })
          }
        )
      }
     
    },

    onActionPressed(newStatus, folder) {
      folder.folder_status = newStatus
      this.update_folder_status(folder, newStatus)
    },

    onSwitchChanged(folder) {
      this.update_folder_status(folder, folder.folder_status)
    },

    update_folder_status(folder, newStatus) {
      let data = {
        deal_id : this.deal.order_deal_id,
        folder_marks : [
          {
            name : folder.name,
            folder_status : newStatus
          }
        ]
      }
      getDealApi().mark_folder_status(data).then((res) => {
        if (res.errCode == 0) {
  
          this.$alertify.message("Folder Status Updated ");
         
        } else {
          this.$alertify.message("Failed to Update " + res.msg);
        }
      })
    },

    file_upload(files) {
     
      files.map((f) => {
        console.log(f)
        let folder = this.deal.folders.find(e => e.id == f.folder_id);
        if (folder) {
          if (!folder.files) {
              folder.files = []
            }
          folder.files.push(f)
        }

        this.deal.folder_group.map(g => {
          let folder = g.folders.find(e => e.id == f.folder_id);
          if (folder) {
            if (!folder.files) {
              folder.files = []
            }
            folder.files.push(f)
           
          }
        })
        
      })

      this.$bvModal.hide('modal-file-upload-dialog')
     
    },

    open_file_update_dialog(f) {
      this.current_file = f
      this.file_name = f.file_name
      this.$bvModal.show('modal-file-name-update-dialog')
    },

    file_name_save(new_file_name) {
      let data = {
        new_file_name : new_file_name,
        deal_id       : this.deal.order_deal_id,
        file_id       : this.current_file.id
      }
      getDealApi().update_file_name(data).then(res => {
        if (res.errCode == 0) {
          this.current_file.file_name = new_file_name
          this.$bvModal.hide('modal-file-name-update-dialog')
        }  else {
          this.$alertify.error("Update File Name Failed " + res.errCode);
        }
      })
      
    },

    open_mail_dialog(attachments) {
      this.selected_files = attachments
      this.$bvModal.show('modal-send-mail-dialog')
      this.$bvModal.hide('modal-files-download-dialog')
    },

    onPdfError(e) {
      console.log(e)
    },

    openHandler(pdfApp) {
        pdfApp.pdfViewer.pdfDocument.getPage(1).then((page) => {
        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale, });
        // Support HiDPI-screens.
        var outputScale = window.devicePixelRatio || 1;


        var canvas =  document.createElement('canvas');
       // var context = canvas.getContext('2d');

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height =  Math.floor(viewport.height) + "px";
        canvas.style.position = "absolute";
        canvas.style.left = "0px";
        canvas.style.top = "0px";
        canvas.style.zIndex = "100";



        var container = document.getElementById('viewerContainer')
        container.appendChild(canvas)

      })
    },

    postMessage() {

      let data = {
        deal_id : this.deal.order_deal_id,
        message : this.content,
      }
      getDealApi().post_deal_message(data).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Post Success ");
          this.$bvModal.hide('modal-post-message-dialog')
        } else {
          this.$alertify.error("Post Failed " + res.errCode);
        }
      })
      
    }




  },

  created() {


  },

  mounted() {
      
  }

};
</script>

<template>
  <div class="row">

    <div class="row" >
      <div class="col-md-6">
       
      </div>

      <div class="col-md-6">
        <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">
          <button type="button" class="btn btn-light" v-b-modal.modal-post-message-dialog>Post Message</button>

          
          <button type="button" class="btn btn-light" v-b-modal.modal-file-upload-dialog>
            Upload Files
          </button>

          <button type="button" class="btn btn-light" v-b-modal.modal-files-download-dialog>
            Download Files
          </button>

          <b-modal centered id="modal-file-upload-dialog" title="File Upload" size="lg" hide-footer>
            <FileUploadDialog :deal="deal" @cancel="$bvModal.hide('modal-file-upload-dialog')" z-index="9999999"
              @confirm="file_upload" />
          </b-modal>

          <b-modal centered id="modal-file-name-update-dialog" title="File Name Update" size="lg" hide-footer>
            <FileNameUpdateDialog :file_name="file_name" @cancel="$bvModal.hide('modal-file-name-update-dialog')"
              @confirm="file_name_save" />
          </b-modal>

          <b-modal centered id="modal-files-download-dialog" title="Files Download" size="lg" hide-footer style="z-index: 999999" >
            <DownloadFilesDialog :deal="deal" @cancel="$bvModal.hide('modal-files-download-dialog')" @send="open_mail_dialog"/>
          </b-modal>

          <b-modal centered id="modal-send-mail-dialog" title="Files Download" size="lg" hide-footer >
            <SendMailDialog :deal="deal" :attachments="selected_files" @cancel="$bvModal.hide('modal-send-mail-dialog')"  @confirm="$bvModal.hide('modal-send-mail-dialog')"/>
          </b-modal>

          <b-modal centered id="modal-post-message-dialog" title="Post Message" size="lg" hide-footer style="z-index: 999999" >
           
              <form class="needs-validation" @submit.prevent="postMessage">

                  <div class="mb-3">
                    <ckeditor v-model="content" :editor="editor"></ckeditor>
                  </div>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$bvModal.hide('modal-post-message-dialog')">Cancel</b-button>
                            <b-button variant="primary" type="submit"> Post </b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->
              </form>
          
          </b-modal>

          
        </div>
      </div>
    </div>
    <!-- end row -->

    

    <div class="row">
      <b-tabs class="" content-class="card card-body mt-3" nav-class="nav-tabs-custom"  nav-wrapper-class="nav-tabs-custom">
        <b-tab v-for="(folder, idx) in deal.folder_group" :key="'folder_group_'+idx" :title="folder.name" >
          <div class="row"   style="min-height: 100dvh;">
            <div class="col-md-6">
              <FolderTree ref="folderTree" :folders="folder.folders" 
                :show_tag="true" :show_status="true" :show_actions="true"
                :actions="['Approve','Missing','Missing Initial','Missing Sign','Missing Date']" :show_file_edit_actions="true" :file_actions="['Edit','Download','Delete']"
                @fileDownload="fileDownload"  @actionPressed="onActionPressed" @fileActionTriggered="fileEdit"
                @switchChanged="onSwitchChanged"
                />
            </div>
            <div class="col-md-6">
              <vue-pdf-app :config="pdf_previe_config" :pdf="preview_file_url" @open="openHandler"></vue-pdf-app> 
              <!-- <PdfPreview
                src="https://manage.mobilerealty.ca/res/deal_report/paper.pdf"
                @error="onPdfError"
              ></PdfPreview> -->
            
            </div>
          </div>
          <!-- end row -->
        </b-tab>
      </b-tabs>
    </div>
    <!-- end row -->

  </div>
</template>