import request from '@/utils/request';


class DealProcessApi {
    
    remove_subject = (data)=> {
        
        return request({
            url: '/adm/deal/process/remove_subject',
            method: 'post',
            data
        });	
    }

    post_cheque_deposit = (data)=> {
        
        return request({
            url: '/adm/deal/process/deposit',
            method: 'post',
            data
        });	
    }
    

    excess_deposit = (data) => {
        return request({
            url: '/adm/deal/process/excess_deposit',
            method: 'post',
            data
        });	
    }


    close_deal = (data) => {
        return request({
            url: '/adm/deal/process/finalized',
            method: 'post',
            data
        });	
    }

    interest_transfer = (data) => {
        return request({
            url: '/adm/deal/process/make_term_transfer',
            method: 'post',
            data
        });	
    }

    collapse = (data) => {
        return request({
            url: '/adm/deal/process/collapse',
            method: 'post',
            data
        });	
    }

    


}

let _api = null

const getDealProcessApi = () => {
    if (!_api) {
        _api = new DealProcessApi();
    }
    return _api;
}

export { getDealProcessApi };


