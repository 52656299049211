<script>


/**
 * Property component
 */

import {
    required,
} from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Choices from "choices.js";


import {getDealApi} from '@/api/deal'


export default {

    emits: ['confirm', 'cancel'],

    props: ['deal','attachments'],
    components: {
        ckeditor: CKEditor.component
    },

    validations: {
       
        subject: { required },

    },



    data() {

        return {
            editor: ClassicEditor,
            subject: '',
            to: '',
            content: '',
            files : '',
            subject_choice: {},
            show_error : false
        }

    },
    methods: {
        formSubmit() {
           
            this.$v.$touch();
            this.show_error = this.subject_choice.getValue(true).length <= 0
            if (this.$v.$error == false && this.show_error == false) {
                this.send()
            }
        },

        send() {
            console.log(this.subject_choice.getValue(true))
            let file_ids = []
            this.attachments.map(a => {
                file_ids.push(a.id)
            })
            let data = {
                deal_id : this.deal.order_deal_id,
                files : [...file_ids],
                mail : {
                    to : this.subject_choice.getValue(true),
                    subject : this.subject,
                    content :this.content
                }
            }
            getDealApi().send_files(data).then(res => {
                if(res.errCode == 0) {
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Send Mail failed " + res.errCode);
                }
            })
        }
    },

    created() {

    },

    mounted() {

        if (this.attachments) {
            this.attachments.map(n => {
                this.files += n.file_name +","
            })
        }
        this.subject_choice = new Choices('#subject_choice', {
            placeholderValue: "Input mail address ",
            searchPlaceholderValue: "eeee",
            delimiter: ",",
            
            maxItemCount: 5,
            removeItemButton: true,
        })
       

    },

  

};
</script>



<template>
    <div class="card" >

        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">


                <div class="row">

                    <div class="mb-3">
                        <label for="subject_type" class="form-label font-size-13 text-muted">To</label>
                        <input class="form-control" ref="to_choice"  type="text" id="subject_choice"
                             />
                    </div>

                    <b-alert show variant="danger" v-if="show_error" >This value is required.</b-alert>

                </div>
                <div class="row">

                    <div class="mb-3">
                        <label for="subject_choice" class="form-label font-size-13 text-muted">Subject</label>
                        <input class="form-control" v-model="subject" :class="{
                            'is-invalid': $v.subject.$error,
                        }" />

                        <div v-if="$v.subject.$error" class="invalid-feedback">
                            <span v-if="!$v.subject.required">This value is required.</span>
                        </div>
                    </div>

                </div>
                <div class="row" v-if="this.files != '' ">
                    <div class="mb-3">
                        <label for="subject_choice" class="form-label font-size-13 text-muted">Attachments</label>
                        <div class="form-label font-size-13 text-muted">{{files}}</div>
                    </div>
                </div>
                <div class="row">

                    <div class="mb-3">
                        <label for="validationCustom02">Content</label>
                        <ckeditor v-model="content" :editor="editor"></ckeditor>
                    </div>

                </div>
                <!-- end row-->

               

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Send</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>