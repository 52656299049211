<script>




export default {

    emits: ['close',],

    props: ['buyer_seller'],
    components: {

    },

    validations: {

    },



    data() {
        return {
           
        }
    },

    computed: {
        full_name()  {
            return this.buyer_seller.first_name +' '+ this.buyer_seller.last_name;
        }
    },

    methods: {


    },

    created() {

    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
           

                <div class="row">
                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Name</label>
                            <input class="form-control" type="text" :value="full_name" readonly />

                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input class="form-control" type="text"  v-model="buyer_seller.phone"  readonly />

                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input class="form-control" type="text"   v-model="buyer_seller.email" readonly />

                        </div>
                    </div>
                    <!-- end col -->

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-xl-8">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Address</label>
                            <input class="form-control" type="text" :value="buyer_seller.address" readonly />

                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="primary" type="button"  @click=" $emit('close')">Close</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->

        </div>
    </div>
    <!-- end card -->
</template>