<script>


/**
 * Listng Action Update component
 */


import simplebar from "simplebar-vue";
import Choices from "choices.js";

import { getListingApi } from "@/api/listing"


export default {

    emits: ['confirm', 'cancel'],


    components: {
        simplebar
    },


    data() {

        return {
            submitted: false,
            reasons: [],
            reason_items: [],
            checked_reason_items: [],
            current_reason_file: "",
            profile: require("@/assets/images/users/avatar-2.jpg"),
            choice_obj: {},
            other_reason : ''
        }

    },
    methods: {
        formSubmit() {
            this.submitted = true;
            let refuse_reasons = []
            this.reasons.map((f) => {
                f.reasons.map((r) => {
                    if (r.checked == true) {
                        refuse_reasons.push(`${r.parent_label}-${r.name}`)
                    }
                })
            })
            refuse_reasons.push(this.other_reason)
            
            this.$emit('confirm', refuse_reasons)

        },

        reason_category_changed() {

            this.reason_items = []
            this.reasons.map((r) => {
                if (r.value == this.current_reason_file) {
                    this.reason_items = r.reasons
                }
            })
        },
        reason_check_evt(evt) {
            let checked_item = evt.target._value

            if (evt.target.checked) {
                let parent = this.reasons.find((e) => e.value == checked_item.parent_id)
                checked_item.parent_label = parent.label
                this.checked_reason_items.push(checked_item)
            } else {
                this.checked_reason_items = this.checked_reason_items.filter((e) => e.code != checked_item.code)
            }
        },

        reason_remove(item) {
            let checked_item = item
            this.checked_reason_items = this.checked_reason_items.filter((e) => e.code != checked_item.code)
        }
    },

    created() {
        getListingApi().refuse_reason_list().then((res) => {
            if (res.errCode == 0) {
                res.data.map((r) => {
                    this.reasons.push({
                        label: r.name,
                        value: r.code,
                        selected: false,
                        reasons: r.reasons,
                    })


                })
                this.choice_obj.setChoices(this.reasons)
            }
        })
    },

    mounted() {
        this.choice_obj = new Choices("#choices-listing-file", {
            searchEnabled: true,
            removeItemButton: false,
        })


    }

};
</script>

<template>
    <div class="row">
        <div class=" row  justify-content-between d-flex align-items-center">
            <div class="col-md-6">
                <label>Reject Reason Category:</label>
                <select class="form-control" name="choices-listing-file" id="choices-listing-file" data-trigger
                    @change="reason_category_changed" v-model="current_reason_file"
                    placeholder="select refuse reason categorys">
                </select>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 card-body">
                <simplebar id="chat-list" style="height: 40vh;">
                    <div class="table-responsive mb-0">
                        <b-table :items="reason_items"
                            :fields="[{ key: 'F', sortable: false }, { key: 'FileName', sortable: false }]" responsive="sm"
                            :per-page="reason_items.length" :current-page="1">
                            <template #cell(F)="data">
                                <div class="form-check font-size-16" width="50px;">
                                    <input type="checkbox" class="form-check-input" :value="data.item"
                                        v-model="data.item.checked" @change="reason_check_evt" />

                                </div>
                            </template>
                            <template #cell(FileName)="data">
                                {{ data.item.name }}
                            </template>

                        </b-table>
                    </div>
                </simplebar>
            </div>
            <div class="col-md-6  card-body">
                <simplebar style="height: 40vh;">
                    <div class="table-responsive mb-0">
                        <b-table :items="checked_reason_items"
                            :fields="[{ key: 'Reason', sortable: false }, { key: 'Action', sortable: false }]"
                            responsive="sm" :per-page="checked_reason_items.length" :current-page="1">
                            <template #cell(Reason)="data">
                               {{ data.item.parent_label }} - {{ data.item.name }}
                            </template>

                            <template #cell(Action)="data">
                                <div class="icon-demo-content">
                                    <div class="col-xl-3 col-lg-4 col-sm-6 pt-0 mt-0">
                                        <i class="mdi mdi-close" @click="reason_remove(data.item)"></i>
                                    </div>
                                </div>
                            </template>

                        </b-table>

                    </div>
                </simplebar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6  card-body">
                <textarea class="form-control" v-model="other_reason" placeholder="Other Reason" rows="4"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="d-flex  gap-2  col-md-2 mx-auto">
            </div>

            <div class="d-flex  gap-2  col-md-6 mx-auto">
                <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                <b-button variant="primary" type="button" @click="formSubmit">Save</b-button>
            </div>


        </div>
        <!-- end row-->
    </div>
    <!-- end card -->
</template>