<script>


/**
 * Conveyance Info TAB component
 */




import { financial } from '@/api/misc'

export default {

  emits: ['confirm', 'cancel'],

  props: {

    deal : {
        type : Object,
        default: () => ({
          accounting : {
            refs : [],
            sub_ledger : [],
          },
        })
     },
  },

  components: {
  },

  data() {

    return {
    }

  },
  computed: {
    btn_disable_flag() {
      return this.deal.order_state == 'CLOSED' || this.deal.order_state == 'COLLAPSED'
    }
  },

  methods: {
    f(n, d = 2) {
      return financial(n, d)
    },



  },

  created() {
    if (!this.deal.accounting) {
      this.deal.accounting = {
        refs : [],
        sub_ledger : [],
      }
    }

  },

  mounted() {
    console.log(this.deal.accounting)
  }

};
</script>

<template>
  <div>

    <div class="d-flex align-items-start">
      <div class="flex-grow-1">

      </div>


      <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">

        <div class="hstack gap-3">

        </div>

        <div class=" gap-3">

        </div>
      </div>
    </div>


    <div class="col-lg-24">

      <div class="row" v-for="(ref, idx ) in deal.accounting.refs" :key="'acc_ref' + ref.id + '_' + idx">
        <div class="row pt-4">
          <div class="col-sm-2">
            <div class="text-muted">
              <p class="mb-1">Reference </p>
            </div>
          </div>

          <div class="col-sm-5  ">
            Description
          </div>
          <div class="col-sm-2 ">
            Date
          </div>
          <div class="col-sm-1">
            Acct
          </div>
          <div class="col-sm-2  text-sm-end">
            Amount
          </div>
        </div>
        <hr class="my-1" />
        <div class="row" v-for="(gl, index) in ref.gl_entries" :key="'acc_gl' + gl.id + '_' + index">
          <div class="col-sm-2">
            {{ ref.cash_bearer_number }}
          </div>

          <div class="col-sm-5  ">
            {{ gl.description }}
          </div>
          <div class="col-sm-2 ">
            {{ ref.date }}
          </div>
          <div class="col-sm-1">
            {{ gl.gl_account }}
          </div>
          <div class="col-sm-2  text-sm-end">
            ${{ gl.amount.toLocaleString() }}
          </div>
        </div>

      </div>

      <div class="row pt-5">
      </div>
      <div class="row" v-for="(ledger, ledger_idx ) in deal.accounting.sub_ledgers" :key="'acc_sub_ledger' + ledger.id + '_' + ledger_idx">
        <div class="row">
          <div class="col-sm-3">
            <h5 class="mb-0"> {{ ledger.title }} Sub Ledger</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
           Description
          </div>
          <div class="col-sm-2 ">
            Reference
          </div>
          <div class="col-sm-2">
           Date
          </div>
          <div class="col-sm-2  text-sm-end">
            Amount
          </div>
        </div>
        <hr class="my-1" />
        <div class="row" v-for="(ledger_item, i) in ledger.gl_entries" :key="'leger_item_'+i">
          <div class="col-sm-6">
           {{ ledger_item.description }}
          </div>
          <div class="col-sm-2 ">
            {{ ledger_item.reference }}
          </div>
          <div class="col-sm-2">
            {{ ledger_item.date }}
          </div>
          <div class="col-sm-2  text-sm-end">
            {{ ledger_item.amount.toLocaleString() }}
          </div>
        </div>

        <div class="row">
          <div class="col-sm-10"></div>
          <div class="col-sm-2  text-sm-end">
            <hr class="my-1" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10"></div>
          <div class="col-sm-2  text-sm-end">
            {{ ledger.balance.toLocaleString() }}
          </div>
        </div>
      </div>

    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>