<script>


/**
 * Deposit Edit component
 */




//import vue2Dropzone from "vue2-dropzone";
//import "vue2-dropzone/dist/vue2Dropzone.min.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { required,between } from "vuelidate/lib/validators";

import { getDealBasicApi } from '@/api/deal/basic'
import {getDealProcessApi} from '@/api/deal/process'
import { financial } from '@/api/misc'
import moment from 'moment'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],

    components: {
       // vueDropzone: vue2Dropzone,
        flatPickr,
        VueBootstrapTypeahead,
    },

    validations() {
        return {
            payee: { required},
            post_date: { required },
            excess_amount: { required, between:between(0, this.max_amount)  },
        }
    },

    computed: {
        max_amount() {
            return this.total_balance
        }
    },


    data() {
        return {
            file_validate: true,
            file: {},
            payee_list : [],

            payee: '',
            payee_address: '',
            payee_id     : '',
            payee_email1 : '',
            payee_email2 : '',
            payee_phone1 : '',

            excess_amount: '',
            post_date : '',
            total_commission : '',
            total_balance : 0,
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 10,
            },

        }
    },

    methods: {

        f(n, d = 2) {
            return financial(n, d)
        },

        formSubmit() {

            this.$v.$touch();
            if (this.$v.$invalid == false) {
                this.$alertify.confirmWithTitle(
                "Confirmation",
                "Are you sure to Process Excess Deposit?",
                () => {
                    
                    // if (this.file_validate == true) {
                    //     this.$alertify.error("Please Upload scaned cheque ");
                    //     return;
                    // }

                    let data = {
                        order_deal_no : this.deal.order_deal_id,
                        amount        : Number(this.excess_amount),
                        payee         : this.payee,
                        payee_address : this.payee_address,
                        payee_id      : this.payee_id
                    }
                    getDealProcessApi().excess_deposit(data).then(res => {
                        if (res.errCode == 0) {
                            this.$emit('confirm')
                        } else {
                            this.$alertify.error("Handle Failed " + res.errCode);
                        }
                    })
                })
            }

        },

        fileUploaded(ev, resp) {
           
            if (resp[0].errCode == 200) {
                this.file_validate = false
                this.file = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name: resp[0].response.filename,
                    size: resp[0].response.size,
                    type: resp[0].response.type
                }
            } else {
                this.file_validate = true
            }
        },

        onPayeeSelect(evt) {
            this.payee_address = evt.address
            this.payee_id      = evt.id
            this.payee_email1  = evt.email
            this.payee_email2  = evt.email2
            this.payee_phone1  = evt.phone
        }



    },

    created() {

    },

    mounted() {

        getDealBasicApi().query_agent_commission({order_deal_no : this.deal.order_deal_id}).then(res => {
            if (res.errCode == 0) {
                this.total_commission = res.data.amount
                this.excess_amount = Number(this.deal.balance) - this.total_commission
                this.total_balance = Number(this.deal.balance) - this.total_commission
            }
        })

        this.post_date = moment().format("YYYY-MM-DD")
        

        this.deal.sellers.map(e => e.name = e.first_name+' '+e.last_name)
        this.deal.buyers.map(e => e.name = e.first_name+' '+e.last_name)
        let lawyers = this.deal.lawyers
        lawyers.map(e => {
            e.name = e.name +' IN TRUST'
        })
        this.payee_list.push(...this.deal.sellers, ...this.deal.lawyers,...this.deal.buyers)

       
        let buyer_lawyer = lawyers.find(e => e.type == 'Buyer Lawyer')
        if (buyer_lawyer) {
            this.payee_address = buyer_lawyer.address
            this.payee_id      = buyer_lawyer.id
            this.payee_email1  = buyer_lawyer.email
            this.payee_email2  = buyer_lawyer.email2
            this.payee_phone1  = buyer_lawyer.phone
            this.payee         = buyer_lawyer.name
            this.$refs['excess_payee_ref'].inputValue = buyer_lawyer.name
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">


                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label  class="form-label">Deposit In Trust</label>
                            <input v-model="deal.balance"  class="form-control"  type="text" readonly />
                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label  class="form-label">Commission Belong us</label>
                            <input v-model="total_commission" class="form-control"  type="text" readonly />
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label  class="form-label">Amount</label>
                            <input v-model="excess_amount" class="form-control"  type="text" :class="{
                                'is-invalid': $v.excess_amount.$error,
                            }" />
                            <div v-if="$v.excess_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.excess_amount.required">This value is required.</span>
                                <span v-if="!$v.excess_amount.between">This value Must less than {{ max_amount }}.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label  class="form-label">Post Date</label>
                            <flat-pickr  v-model="post_date" class="form-control"  type="text" :class="{
                                'is-invalid': $v.post_date.$error,
                            }" />
                            <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end row-->

                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Payee</label>
                            <vue-bootstrap-typeahead ref="excess_payee_ref" v-model="payee" 
                                :data="payee_list" :inputClass="{ 'is-invalid': $v.payee.$error }" :remoteMatch="false"
                                :serializer="s => s.name" :foramterDisplay="s => s.name" @hit="onPayeeSelect($event)"
                                autocomplete="off" />
                            <div v-if="$v.payee.$error" class="invalid-feedback">
                                <span v-if="!$v.payee.required">This value is required.</span>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-8">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Payee Address</label>
                            <input v-model="payee_address" class="form-control" type="text" />
                        </div>

                    </div>
                </div>
                <!-- end row-->

                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Email1</label>
                            <input v-model="payee_email1" class="form-control" type="text" />
                           
                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Email2</label>
                            <input v-model="payee_email2" class="form-control" type="text" />
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Phone</label>
                            <input v-model="payee_phone1" class="form-control" type="text" />
                        </div>

                    </div>
                </div>
                <!-- end row-->


                <div class="row mt-3">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>