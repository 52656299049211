<script>

import {financial} from '@/api/misc'

export default {


    props: ['deal'],

    data() {
        return {
            is_subject_removed : false,
        }
    },

    methods: {
        f(n, d=2) {
            return financial(n, d)
        },
    },
    created() {


    },

    mounted() {
       
    }
}
</script>

<template>
    <div class="row">
        <div class="col-lg-3">
            <div class="mt-3">
                <ul class="text-muted">

                    <li class="py-1 font-size-15 ">Status: {{ deal.order_state }}</li>
                   
                    <li class="py-1 font-size-15 ">Commission: ${{ f(deal.listing_commission + deal.listing_commission_gst).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Agent:  {{ deal.agents[0].first_name +' ' + deal.agents[0].last_name }}</li>
                    <li class="py-1 font-size-15 ">Operator: {{ deal.process_staff_name }}</li>

                </ul>
            </div>
        </div>
        <!-- end col -->

      
        <div class="col-lg-4">
            

            <div class="row">

                <div class="col-lg-12 col-sm-12">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-check-circle text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Balance In Trust</h5>
                            <p class="text-muted mb-0">${{ f(deal.balance).toLocaleString() }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->



            </div>
            <!-- end row -->


        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</template>