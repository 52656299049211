<script>




export default {

    emits: ['close'],

    props: ['agent'],
    components: {

    },

    validations: {

    },



    data() {
        return {
            submitted: false,


        }
    },

    methods: {




    },

    created() {

    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-xl-4">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Brokerage</label>
                            <input class="form-control" type="text" v-model="agent.brokerage" readonly />

                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-3">
                        <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input class="form-control" type="text"  v-model="agent.phone"  readonly />

                        </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-5">
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input class="form-control" type="text"  v-model="agent.email"  readonly />

                        </div>
                    </div>
                    <!-- end col -->

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-xl-8">
                        <div class="mb-3">
                            <label for="formFileSm" class="form-label">Address</label>
                            <input class="form-control" type="text"  v-model="agent.address"  readonly />

                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row-->



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">

                            <b-button variant="primary" type="button"  @click=" $emit('close')">Close</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>