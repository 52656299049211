<script>

import LocalAagentViewDialog from './local_agent_view_dialog.vue';
import LawyerViewDialog from './lawyer_view_dialog.vue'
import BuyerSellerViewDialog from './buyer_seller_view_dialog.vue'
import {financial} from '@/api/misc'

export default {


    props: ['deal'],

    components : {
        LocalAagentViewDialog,
        LawyerViewDialog,
        BuyerSellerViewDialog
    },

    data() {
       return {
        agent : {},
        buyer_seller : {},
        lawyer: {},
        seller_lawyers : [],
        buyer_lawyers : [],
        lawyers: [],
        
       }
    },

    computed: {
        is_subject_removed() {
            return this.deal?(this.deal.is_subject_deal == 1 ? (this.deal.subject_remove_flag == 1?true : false) : true) : false;
        }
    },

    methods: {
        f(n, d=2) {
            return financial(n, d)
        },

        showAgentView(a) {
            this.agent = a
            this.agent.brokerage = a.name
            this.$bvModal.show('agent-view-dialog')
        },

        showBuyerView(a) {
            this.buyer_seller = a
            this.$bvModal.show('buyer-seller-view-dialog')
        },

        showSellerView(a) {
            this.buyer_seller = a
            this.$bvModal.show('buyer-seller-view-dialog')
        },

        showLawyerView(a) {
            this.lawyer = a
            this.$bvModal.show('lawyer-view-dialog')
        },

        updateDeal(d) {
            this.seller_lawyers = d.lawyers.filter(e => e.type == 'Seller Lawyer')
            this.buyer_lawyers  = d.lawyers.filter(e => e.type == 'Buyer Lawyer')
        }
    },

    created() {


    },

    mounted() {
        this.seller_lawyers  = this.lawyers.filter(e => e.type == 'Seller Lawyer')
        this.buyer_lawyers   = this.lawyers.filter(e => e.type == 'Buyer Lawyer')
        
    }
}
</script>

<template>

    <div class="row">
        <b-modal centerd id="agent-view-dialog" :title="agent.first_name +' '+agent.last_name" size="lg" hide-footer>
            <LocalAagentViewDialog :agent="agent" @close="$bvModal.hide('agent-view-dialog')"  />
        </b-modal>

        <b-modal centerd id="lawyer-view-dialog" :title="lawyer.name" size="lg" hide-footer>
            <LawyerViewDialog :lawyer="lawyer" @close="$bvModal.hide('lawyer-view-dialog')"  />
        </b-modal>
        <b-modal centerd id="buyer-seller-view-dialog" :title="buyer_seller.first_name+' '+buyer_seller.last_name" size="lg" hide-footer>
            <BuyerSellerViewDialog :buyer_seller="buyer_seller" @close="$bvModal.hide('buyer-seller-view-dialog')"  />
        </b-modal>

        

        <div class="col-lg-2">
            <div class="mt-3">
                <ul class="text-muted">

                    <li class="py-1 font-size-15 ">Status: 
                        <b-badge class="badge-soft-primary me-2">{{ deal.order_state }}</b-badge>
                    </li>
                    <li class="py-1 font-size-15 ">Price: ${{ f(deal.selling_price).toLocaleString() }}</li>
                   

                    <li class="py-1 font-size-15 ">Submitter: {{ deal.process_staff_name }}</li>
                    <li class="py-1 font-size-15 ">Conveyancer: {{ deal.process_staff_name }}</li>

                    <li class="py-1 font-size-15 " v-if="deal.deal_business =='M'">
                        <span v-for="(sub_deal, idx) in deal.links" :key="'sub_deal_state_'+idx">
                            {{sub_deal.order_deal_id }} :  {{ sub_deal.order_state }}
                        </span>
                    </li>

                </ul>
            </div>
        </div>
        <!-- end col -->

        <div class="col-lg-3">
            <div class="mt-3">
                <ul class="text-muted">
                    <li class="py-1 font-size-15 ">Seller:</li>
                    <ul v-for="(seller, idx) in deal.sellers" class="py-1 font-size-15 " :key="'deal_sellers_' + idx">
                        <a  href="javascript:void(0);" class="card-link text-custom" @click="showSellerView(seller)">{{ seller.first_name +' ' + seller.last_name  }} </a>
                    </ul>
                    <li class="py-1 font-size-15 ">Listing Commission: ${{ f(deal.listing_commission + deal.listing_commission_gst).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Listing Agent: <br />
                        <span v-for="(a, idx) in deal.listing_brokers" class="font-size-14 " :key="'listing_agent_' + idx">
                            <ul>{{ `${a.name}` }}</ul>
                            <ul><a  href="javascript:void(0);" class="card-link text-custom" @click="showAgentView(a)">{{  `${a.first_name} ${a.last_name}` }}</a>  </ul>
                        </span>
                    </li>
                    <li class="py-1 font-size-15 ">Seller's Lawyer:</li>
                    <ul v-for="(lawyer, idx) in seller_lawyers" :key="'seller_lawyer_'+idx">
                        <a  href="javascript:void(0);" class="card-link text-custom" @click="showLawyerView(lawyer)">{{  `${lawyer.name}` }}</a> 
                    </ul>
                   
                </ul>
            </div>
        </div>


        <div class="col-lg-3">
            <div class="mt-3">
                <ul class="text-muted">
                    <li class="py-1 font-size-15 ">Buyer:</li>
                    <ul v-for="(buyer, idx) in deal.buyers" class="py-1 font-size-15 " :key="'deal_buyers_' + idx">
                        <a  href="javascript:void(0);" class="card-link text-custom" @click="showBuyerView(buyer)">{{ buyer.first_name +' ' + buyer.last_name  }} </a>
                    </ul>
                    
                    <li class="py-1 font-size-15 ">Selling Commission: ${{ f(deal.selling_commission + deal.selling_commission_gst).toLocaleString() }}</li>
                    <li class="py-1 font-size-15 ">Selling Agent: <br />
                        <span v-for="(a, idx) in deal.selling_brokers" class="font-size-14 " :key="'selling_agent_' + idx">
                            <ul>{{ `${a.name}` }}</ul>
                            <ul><a  href="javascript:void(0);" class="card-link text-custom" @click="showAgentView(a)">{{  `${a.first_name} ${a.last_name}` }}</a>  </ul>
                        </span>
                    </li>

                    <li class="py-1 font-size-15 ">Buyer's Lawyer:<br /> </li>
                    <ul v-for="(lawyer, idx) in buyer_lawyers" :key="'buyers_lawyer_'+idx">
                        <a  href="javascript:void(0);" class="card-link text-custom" @click="showLawyerView(lawyer)">{{  `${lawyer.name}` }}</a> 
                    </ul>
                   
                </ul>
            </div>
        </div>


        <div class="col-lg-3">
            <div class="row">

                <div class="col-lg-6 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-13 mb-1">{{ deal.is_subject_deal?'Subject Remove' :'Recession Date'}}</h5>
                            <p class="text-muted mb-0">{{ deal.is_subject_deal?deal.subject_remove_date : deal.recission_date }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->
                <div class="col-lg-6 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-check-circle  font-size-22"
                                :class="deal.is_subject_deal  && deal.subject_remove_flag == 1 ? 'text-primary' : 'd-none'"></i>
                        </div>
                        <div class="flex-grow-1" v-if="deal.is_subject_deal && deal.subject_remove_flag == 1">
                            <b-badge class="bg-primary me-5 font-size-14">Subject Removed</b-badge>
                            <p class="text-muted mb-0"></p>
                        </div>
                    </div>
                </div>
                <!-- end col -->


            </div>
            <!-- end row -->
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Acceptance</h5>
                            <p class="text-muted mb-0">{{ deal.acceptance_date }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6 col-sm-6" v-if="deal.closing_date">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3" >
                            <i class="uil uil-check-circle text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <b-badge class="bg-success me-5 font-size-14">Closed</b-badge>
                            <p class="text-muted mb-0">{{ deal.closing_date }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->

            </div>
            <!-- end row -->

            <div class="row">

                <div class="col-lg-6 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-calendar-alt text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Completion</h5>
                            <p class="text-muted mb-0">{{ deal.completion_date }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="d-flex align-items-center mt-4">
                        <div class="flex-shrink-0 me-3">
                            <i class="uil uil-check-circle text-primary font-size-22"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="font-size-15 mb-1">Balance</h5>
                            <p class="text-muted mb-0">${{ f(deal.balance).toLocaleString() }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col -->

            </div>
            <!-- end row -->


        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</template>