<script>


/**
 * Commission Edit component
 */


import {
    required,
} from "vuelidate/lib/validators";
import AgentAutoComplete from '@/components/agent-auto-complete-input'

import {financial} from '@/api/misc'


import { getDealApi } from '@/api/deal'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        //flatPickr
        AgentAutoComplete,
    },

    validations: {
        agents : {
            $each: {
                origin_commission : {required},
                origin_gst : {required}
            }
        }
    },



    data() {
        return {
            agents : [],
            deleted_agents : [],
            choice_obj : [],
            el_list    : [],
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                let update_agents = []
                update_agents.push(...this.agents)
                update_agents.push(...this.deleted_agents)
                update_agents.map(e => {
                    e.commission = e.origin_commission
                     e.gst = e.origin_gst
                })
                let data = {
                    deal_id            : this.deal.order_deal_id,
                    agent_commissions  : update_agents
                    
                }
                getDealApi().edit_agent_commission(data).then(res => {
                    if (res.errCode == 0) {
                        this.$emit('confirm', data)
                    } else {
                        this.$alertify.error("Failed to Edit Agent Commission Fee " + res.errCode);
                    }
                })
                
            }
        },

        onAddNewAgent() {
            this.agents.push({
                agent_type : 1,
                origin_commission : 0,
                orgin_gst         : 0,
                first_name        : '',
                last_name         : ''
            })

            this.$nextTick(() => {
                this.update_choice_style()
            })
           
        },

        onRemoveAgent(agent, idx) {
            if (this.agents.length <=1 ) {
                this.$alertify.error("Can not delete last agent");
                return
            }
            if (agent.id) {
                let cache_agent = this.agents.find(e => e.id == agent.id)
                cache_agent.is_deleted = 1
                this.deleted_agents.push(agent)
            }
            this.agents.splice(idx, 1)
        },



        onSelectedAgent(selected_agent, evt) {
            let agent = this.agents[evt.$attrs['agent_idx']]
            agent.vin        = selected_agent.agent.code
            agent.first_name = selected_agent.agent.first_name
            agent.last_name  = selected_agent.agent.last_name
            agent.phone      = selected_agent.agent.phone
            agent.brokerage  = 'Remax Master'
            agent.email      = selected_agent.agent.email
            agent.agent_vin  = agent.vin
            agent.name       = selected_agent.agent.first_name+' '+selected_agent.agent.last_name +' ('+selected_agent.agent.code+')'
            agent.init_data  = {agent_name : agent.name, agent_id : ''}
            
        },

        calculate_commission_gst(evt, idx) {
           
            let agent = this.agents[idx]

            if (Number.isNaN(evt.target.value)) {
                return
            }

            agent.origin_gst = financial(Number(evt.target.value) * 0.05, 2)
            
        }

    },

    created() {
        this.agents.push(...this.deal.agents)
       
        this.agents.map(e => {
           e.name             = e.first_name+' '+e.last_name +' ('+e.agent_vin+')'
           e.init_data        = {agent_name : e.name, agent_id : e.id}
           e.origin_agent_vin = e.agent_vin
        })
    },

    mounted() {

    
    }

};
</script>

<template>
    <div class="row">

        <div class="card">

            <div class="card-body">

                <form class="needs-validation" @submit.prevent="formSubmit">

                    <div class="row">
                        <div class="col-sm-4">Agent</div>
                        <div class="col-sm-2">Commission</div>
                        <div class="col-sm-2">Gst</div>
                        <div class="col-sm-1"></div>
                    </div>
                    <div class="row" v-for="(agent, idx) in agents" :key="agent.id + '_' + agent.agent_id + '_' + idx">
                      

                        <div class="col-sm-4">
                            <AgentAutoComplete :agent_idx="idx" v-model="agent.name" :init_data="agent.init_data" @onSelected="onSelectedAgent" autocomplete="off" />
                        </div>

                        <div class="col-sm-2">
                            <input type="text" class="form-control" v-model="agent.origin_commission" :class="{'is-invalid': $v.agents.$each[idx].origin_commission.$error}"  @input="calculate_commission_gst($event, idx)" @blur="calculate_commission_gst($event, idx)"  />
                            <div v-if=" $v.agents.$each[idx].origin_commission.$error" class="invalid-feedback">
                              <span v-if=" $v.agents.$each[idx].origin_commission.$error">This value is required.</span>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <input type="text" class="form-control" v-model="agent.origin_gst" :class="{'is-invalid': $v.agents.$each[idx].origin_gst.$error}"     />
                            <div v-if=" $v.agents.$each[idx].origin_gst.$error" class="invalid-feedback">
                              <span v-if=" $v.agents.$each[idx].origin_gst.$error">This value is required.</span>
                            </div>
                        </div>
                        <!-- div class="col-sm-1">
                            <div class="row icon-demo-content-10 align-right mt-2">
                                <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveAgent(agent, idx)">
                                    <i class="uil-times font-size-4"></i>
                                </div>
                            </div>
                        </div -->

                    </div>


                    <div class="row mt-3">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-6">
                            <div class="d-flex flex-wrap gap-2 text-a">
                                <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                                <b-button variant="primary" type="submit">Save</b-button>
                                <!-- b-button variant="primary" type="button" @click="onAddNewAgent">Add New Agent</b-button -->
                            </div>
                        </div>
                        <div class="col-md-3">

                        </div>

                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </div>
</template>