<script>


/**
 * Conveyance Info TAB component
 */


import AddExraFeeDialg from './add_extra_fee_dialog.vue'
import EditReferralDialog from './add_agent_referral_dialog.vue'

import { getDealApi } from '@/api/deal';
import { financial } from '@/api/misc'

export default {

  emits: ['confirm', 'cancel','close'],

  props: ['deal', 'native_agents'],
  components: {
    AddExraFeeDialg,
    EditReferralDialog,
    
  },

  data() {

    return {
      current_referral : {
        first_name : '',
        last_name  : '',
        agent_name : '',
      }
    }

  },
  computed: {
    btn_disable_flag() {
      return this.deal.order_state == 'CLOSED' || this.deal.order_state == 'COLLAPSED'
    }
  },

  methods: {
    f(n, d = 2) {
      return financial(n, d)
    },

    extra_fee_save(fee) {
      fee.deal_id = this.deal.order_deal_id
      getDealApi().add_extra_fee(fee).then((res) => {
        if (res.errCode == 0) {

          let agent_commission = this.deal.ledger_preview.agent_commissions.find(e => e.agent_id == res.data.agent_id)
          agent_commission.fees.push(res.data)
          this.$alertify.message("Add Extra Fee Succeed");
          this.$bvModal.hide('modal-add-extra-fee-dialog')
          this.recal_commission(agent_commission, '-', fee.amount, fee.gst)
        } else {
          this.$alertify.message("Failed to Add Extra Fee " + res.msg);
        }
      })
    },


    remove_fee(fee, idx) {

      getDealApi().remove_charged_fee({fee_id : fee.id, deal_id : this.deal.order_deal_id}).then((res) => {
        if (res.errCode == 0) {
          let agent_commission = this.deal.ledger_preview.agent_commissions.find(e => e.agent_id == fee.agent_id)
          agent_commission.fees.splice(idx, 1)
          this.$alertify.message("Remove  Fee Succeed");

          this.recal_commission(agent_commission, '+', fee.amount, fee.gst)
        } else {
          this.$alertify.error("Failed to Remove  Fee " + res.errCode);
        }
      })

      
    },



   

    recal_commission(agent_commission, flag, amount ,gst) {
      if (flag == '+') {
        agent_commission.commission  += Number(amount)
        agent_commission.gst         += Number(gst)
      } else {
        agent_commission.commission  -= Number(amount)
        agent_commission.gst         -= Number(gst)
      }
      
    },


    onSaveReferral(referral) {
      referral.deal_id  = this.deal.order_deal_id

      if (referral.referral_id) {
        getDealApi().edit_referral_comm(referral).then(res => {
          if (res.errCode == 0) {
            let agent_commission = this.deal.ledger_preview.agent_commissions.find(e => e.agent_id == referral.agent_id)
            let cache_referal    = agent_commission.referrals.find(e => e.id == referral.referral_id)

        

            this.recal_commission(agent_commission, '+', cache_referal.amount, cache_referal.gst)
            this.recal_commission(agent_commission, '-', Number(referral.amount), Number(referral.gst))

            cache_referal.amount        = financial(Number(referral.amount), 2)
            cache_referal.gst           = financial(Number(referral.gst), 2)
            cache_referal.referral_type = referral.referral_type
            cache_referal.brokerage     = referral.brokerage
            cache_referal.first_name    = referral.first_name
            cache_referal.last_name     = referral.last_name


            this.$alertify.message("Update Referral Succeed");
            this.$bvModal.hide('modal-referral-dialog')
           
          } else {
            this.$alertify.error("Add Referral Failed "+res.errCode);
          }
        })

      } else {
        getDealApi().add_agent_referral(referral).then(res => {
          if (res.errCode == 0) {

            let agent_commission = this.deal.ledger_preview.agent_commissions.find(e => e.agent_id == res.data.agent_id)
            agent_commission.referrals.push(res.data)
            this.$alertify.message("Add Referral Succeed");
            this.$bvModal.hide('modal-referral-dialog')
            this.recal_commission(agent_commission, '-', referral.amount, referral.gst)
          } else {
            this.$alertify.error("Add Referral Failed "+res.errCode);
          }
        })
      }
    },

    onRemoveReferral (referral, idx) {
      getDealApi().delete_agent_referral({referral_id : referral.id, deal_id : this.deal.order_deal_id}).then(res => {
         if (res.errCode == 0) {
          let agent_commission = this.deal.ledger_preview.agent_commissions.find(e => e.agent_id == referral.agent_id)
          agent_commission.referrals.splice(idx, 1)
          this.$alertify.message("Remove Referral Succeed");
          this.recal_commission(agent_commission, '+', referral.amount, referral.gst)
         } else {
          this.$alertify.error("Remove Referral Failed "+res.errCode);
         }
      })
    },

    editReferral(referral) {
      this.current_referral = referral
      this.$bvModal.show('modal-referral-dialog')
    }

    
  },

  created() {


  },

  mounted() {

  }

};
</script>

<template>
  <div>
    
    <div class="d-flex align-items-start">
      <div class="flex-grow-1">

      </div>


      <div class="d-flex flex-wrap align-items-start justify-content-end gap-2 mb-3">

        <div class="hstack gap-3">
            <button type="button" class="btn btn-success"  v-if="deal.deal_business !='M' " :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')" @click="$emit('close')" >Close</button>
            <button type="button" class="btn btn-light" :disabled="btn_disable_flag"  @click="$bvModal.show('modal-add-extra-fee-dialog'+deal.order_deal_id)">  Add Fee  </button>
            <button type="button" class="btn btn-light" :disabled="btn_disable_flag"  @click="$bvModal.show('modal-referral-dialog'+deal.order_deal_id)">  Add Referral  </button>
         
        </div>

        <div class=" gap-3">

          <b-modal centered :id="'modal-add-extra-fee-dialog'+this.deal.order_deal_id" title="Extra Fee" size="lg" hide-footer>
            <AddExraFeeDialg :agent_list="deal.ledger_preview.agent_commissions" @cancel="$bvModal.hide('modal-add-extra-fee-dialog'+deal.order_deal_id)"
              @confirm="extra_fee_save" />
          </b-modal>

          <b-modal centered :id="'modal-referral-dialog'+this.deal.order_deal_id" title="Extra Fee" size="lg" hide-footer >
            <EditReferralDialog :agent_list="deal.ledger_preview.agent_commissions" :referral="current_referral" @cancel="$bvModal.hide('modal-referral-dialog'+deal.order_deal_id)" @confirm="onSaveReferral"/>
          </b-modal>
          

        </div>
      </div>
    </div>


    <div class="col-lg-24">
      <b-tabs class="mt-n5 pt-3" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
        nav-wrapper-class="nav-tabs-custom">
        <b-tab :title="c.full_name" v-for="(c, idx) in deal.ledger_preview.agent_commissions"
          :key="'tab_' + c.id + '_' + idx">
          <div class="row">
            <div class="col-sm-3">
              <div class="text-muted">
                <p class="mb-1">Commission</p>

              </div>
             
            </div>

            <div class="col-sm-4  ">
              <span v-if="c.commission_type == 0">
                {{ f(c.segment_1_rate * 100, 4) }}% On First 100K + {{ f(c.segment_2_rate * 100, 4) }} % On Balance
               <template v-if="c.extra_bonus > 0">${{f(c.extra_bonus).toLocaleString()}} Bonus</template>
              </span>
              <span v-if="c.commission_type == 1">
                {{ f(c.unique_rate * 100, 4) }}% Fixed Rate
                <template v-if="c.extra_bonus > 0">${{f(c.extra_bonus).toLocaleString()}} Bonus</template>
              </span>
              <span v-if="c.commission_type == 2">
                {{ f(c.fixed_amount, 2) }} Fixed
                <template v-if="c.extra_bonus > 0">${{f(c.extra_bonus).toLocaleString()}} Bonus</template>
              </span>
            </div>
            <div class="col-sm-2 ">
              ${{ f(c.origin_commission).toLocaleString() }}
            </div>
            <div class="col-sm-1">
              ${{ f(c.origin_gst).toLocaleString() }}
            </div>
            <div class="col-sm-1  text-sm-end">
              ${{ f(c.total_commission).toLocaleString() }}
            </div>
            <div class="col-sm-1  text-sm-end">
            </div>
          </div>
          <hr class="my-1" />


          
          <div class="row pt-3 ">
            <div class="col-sm-2">
              Fee
            </div>
            <div class="col-sm-5">
              description
            </div>
            <div class="col-sm-2 ">
              Amount
            </div>
            <div class="col-sm-1">
              GST
            </div>
            <div class="col-sm-1  text-sm-end">
              Total
            </div>
            <div class="col-sm-1  text-sm-end">
            </div>
          </div>
          <hr class="my-1" />
          <div class="row" v-for="(fe, idx) in c.fees" :key="c.id + '_' + fe.agent_id + '_' + idx">
            <div class="col-sm-2">
              <div class="text-muted">
                <p class="mb-1">{{ fe.fee_code }}</p>
              </div>
            </div>

            <div class="col-sm-5">
              {{ fe.remarks }}
            </div>
            <div class="col-sm-2  ">
              <p class="mb-1 ">${{ f(fe.amount).toLocaleString() }}</p>
            </div>
            <div class="col-sm-1  ">
              <p class="mb-1 ">${{ f(fe.gst).toLocaleString() }}</p>
            </div>
            <!-- end col -->
            <div class="col-sm-1 ">
              <div class="text-muted text-sm-end">
                <p class="mb-1">${{ f(fe.amount + fe.gst).toLocaleString() }}</p>
              </div>
            </div>
            <!-- end col -->
            <div class="col-sm-1  text-sm-end">
              <div class="row icon-demo-content-10 align-right">
                <div class="col-xl-3 col-lg-4 col-sm-6" @click="remove_fee(fe, idx)"  v-if="btn_disable_flag == false">
                  <i class="uil-times font-size-4"></i></div>
              </div>
            </div>

          </div>
          <!-- end row -->



          <div class="row pt-5 ">
            <div class="col-sm-2">
              OutStanding
            </div>
            <div class="col-sm-2">
              Invoice
            </div>
           
            <div class="col-sm-3">
           
            </div>
            <div class="col-sm-2  ">
              Amount
            </div>
            <div class="col-sm-1 ">
              GST
            </div>
            <div class="col-sm-1  text-sm-end">
              Total
            </div>
            <div class="col-sm-1  text-sm-end">
            </div>
          </div>
          <hr class="my-1" />
          <div class="row" v-for="(fe, idx) in c.bills" :key="c.id + '_' + fe.agent_id + '_' + idx">
            <div class="col-sm-2">
              <div class="text-muted">
                <p class="mb-1">{{ fe.id }}</p>
              </div>
            </div>
            <!-- end col -->
            <div class="col-sm-2">

              <router-link
                  :to="{ name : 'ar-agent-bill-detail', query: {invoice_number : `${ fe.invoice_number }`, view : true }}"
                  class="text-body fw-medium" target="_blank"
                  >{{  fe.invoice_number }}</router-link
                >


            </div>
           
            <div class="col-sm-3">
            
            </div>
            <div class="col-sm-2">
              <div class="text-muted ">
                <p class="mb-1">${{ f(fe.amount).toLocaleString() }}</p>
              </div>
              
            </div>
            <div class="col-sm-1">
              <div class="text-muted ">
                <p class="mb-1">${{ f(fe.gst).toLocaleString() }}</p>
              </div>
              
            </div>
            

            <div class="col-sm-1">
              <div class="text-muted text-sm-end">
                <p class="mb-1">${{ f(fe.amount + fe.gst).toLocaleString() }}</p>
              </div>
              
            </div>

            <div class="col-sm-1 ">
              
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->

          <div class="row pt-4">
            <div class="col-sm-6">
              <div class="text-muted">
                <p class="mb-1">Advanced</p>
              </div>
            </div>
            <!-- end col -->
            <div class="col-sm-5">
              <div class="text-muted text-sm-end h-100">
                <p class="mb-1">${{ c.advance ? f(c.advance).toLocaleString() : 0 }}</p>
              </div>
            </div>
            <!-- end col -->
            <div class="col-sm-1  text-sm-end">
            </div>
             <!-- end col -->
          </div>
          <hr class="my-1" />

          <div class="row pt-4">
          </div>
          <div class="row pt-3">
            <div class="col-sm-2">
              Referral
            </div>

            <div class="col-sm-2">
              Name
            </div>
            <div class="col-sm-3">
              
            </div>
            <div class="col-sm-2">
              Amount
            </div>
            <div class="col-sm-1">
              GST
            </div>
            <div class="col-sm-1  text-sm-end">
              Total
            </div>
             <!-- end col -->
             <div class="col-sm-1"> </div>
             <!-- end col -->

          </div>
          <hr class="my-1" />
          <div class="row pt-3 " v-for="(r, idx) in c.referrals" :key="'r_' + r.id + '_' + idx">
            <div class="col-sm-2">
              {{ idx + 1 }}
            </div>

            <div class="col-sm-2">
              {{ r.first_name +' '+r.last_name }}
            </div>
            <div class="col-sm-3">
              {{ r.brokerage }}
            </div>
            <div class="col-sm-2">
              ${{ r.amount.toLocaleString() }}
            </div>
            <div class="col-sm-1">
              ${{ r.gst.toLocaleString() }}
            </div>
            <div class="col-sm-1  text-sm-end">
              ${{ (r.amount + r.gst).toLocaleString() }}
            </div>
            <div class="col-sm-1">
              <b-dropdown variant="link" toggle-class="shadow-none text-dark" right :disabled="(deal.order_state == 'CLOSED' || deal.order_state == 'COLLAPSED')">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-h"></i>
                </template>
                <li><b-dropdown-item  @click="editReferral(r, idx)"> Edit </b-dropdown-item></li>
                <li><b-dropdown-item  @click="onRemoveReferral(r, idx)"> Remove</b-dropdown-item></li>
                
              </b-dropdown>

              <!-- div class="row icon-demo-content-10 align-right">
                <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveReferral(r, idx)">
                  <i class="uil-times font-size-4"></i></div>
              </div -->
            </div>
          </div>

          <div class="row pt-4">
          </div>
          <div class="row pt-4">
          </div>
          
          <hr class="my-1" />

          <div class="row">
            <div class="col-sm-7">
              <div class="text-muted">
                <h6 class="card-title mb-4">
                  <p class="mb-1">commission</p>
                </h6>
              </div>
            </div>

            <div class="col-sm-2">
              <h6>
                  <p class="mb-1">${{ f(c.commission).toLocaleString() }}</p>
                </h6>
            </div>
            <div class="col-sm-1">
              <h6>
                  <p class="mb-1">${{ f(c.gst).toLocaleString() }}</p>
                </h6>
            </div>

            <div class="col-sm-1">
              <div class="text-muted text-sm-end h-100">
                <h6>
                  <p class="mb-1">${{ f(c.commission + c.gst ).toLocaleString() }}</p>
                </h6>
              </div>
            </div>
             <!-- end col -->
             <div class="col-sm-1"></div>
          </div>
        </b-tab>
      </b-tabs>


      


    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>