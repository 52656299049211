

<script>


/**
 * State Log TAB component
 */

 import {Local} from '@/utils/storage'
import {getDealApi} from '@/api/deal/index'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        
    },

    data() {

        return {
            user : {},
            content : '',
        }

    },
    methods: {
      postMessage() {

        let data = {
          deal_id : this.deal.order_deal_id,
          message : this.content,
        }
        getDealApi().post_deal_message(data).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Post Success ");
            this.$bvModal.hide('modal-post-message-dialog')
            this.deal.state_changed_logs.push(res.data)
          } else {
            this.$alertify.error("Post Failed " + res.errCode);
          }
        })

      }

    },

    created() {
      
    },

    mounted() {

      let loginUser = Local.get("user")
      if (loginUser) {
        this.user = loginUser
      }
      
    },


};
</script>


<template>
    <div class="row">
        <div class="card-header">
              <h5 class="card-title mb-0">State Log</h5>
            </div>
            <div class="card-body">
              <div data-simplebar style="max-height: 280px;">
                <div class="list-group list-group-flush">

                  <div class="list-group-item py-3" v-for="(item, idx) in deal.state_changed_logs" :key="item.id+'_'+idx">
                    <div class="d-flex aligm-items-start">
                      <div class="flex-shrink-0 me-3">
                        <img
                          :src="item.operator.avatar"
                          alt=""
                          class="avatar-sm rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-13 mb-1 text-truncate">
                          <a href="javascript: void(0);" class="text-dark"
                            >{{item.operator.name}}</a
                          >
                          <small class="text-muted ms-1">{{ item.change_time }}</small>
                        </h5>
                        <span class="text-muted mb-0"  v-html="item.change_description"></span>
                      </div>

                    </div>
                  </div>
                  <!-- end list group -->


                </div>
              </div>
              <div class="d-flex chat-input-section align-items-start pt-3">
                <div class="flex-shrink-0 d-none d-sm-block me-3">
                  <img
                  :src="user.avatar"
                    alt=""
                    class="avatar-sm rounded-circle"
                  />
                </div>
                <div class="flex-grow-1">
                  <div class="position-relative d-flex align-items-start">
                    <input
                      type="text"
                      v-model="content"
                      class="form-control chat-input"
                      placeholder="Some text value..."
                    />
                    <div class="chat-input-links d-flex align-items-start">
                      <button type="button" class="btn btn-primary" @click="postMessage">
                        <i class="uil uil-message"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
    </div>
</template>