

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/**
 * Sidepanel component
 */
export default {
    components: { ckeditor: CKEditor.component },

    props: ['subject', 'content'],
    data() {
        return {
            to: [],
            editor: ClassicEditor,
            editorSubject : '',
            editorData: "<p></p>"
        };
    },

    methods: {
        onClose() {
            this.$emit('close')
        },

        onSend() {
            let data = {
                to: this.to,
                subject: this.subject,
                content: this.editorData,
            }
            this.$emit('send', data)
        }
    },

    created() {
        this.editorSubject = this.subject
        this.editorData = this.content
    }


};
</script>

<template>
    <div>
        <form>
            <div class="mb-3">
                <input type="email" class="form-control" placeholder="To" v-model="to" />
            </div>

            <div class="mb-3">
                <input type="text" class="form-control" placeholder="Subject"  v-model="editorSubject"/>
            </div>
            <div class="mb-3">
                <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>
        </form>
        <div class="row text-md-end">
            <div class="hstack  text-md-end ">
                <div class=" ms-auto"></div>
                <div class=" p-1 px-2"><b-button variant="secondary" class="px-2" @click="onClose">Close</b-button></div>
                <div class=" p-1 px-2"><b-button variant="primary" class="px-2" @click="onSend">
                    Send
                    <i class="fab fa-telegram-plane ms-1"></i>
                </b-button></div>
            </div>
        </div>
    </div>
</template>