<script>


/**
 * Subject Remove Dialog
 */

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required,} from "vuelidate/lib/validators";
import { getDealProcessApi } from '@/api/deal/process'

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
   
    components: {
        flatPickr,
        vueDropzone: vue2Dropzone,
    },

    validations: {
        remove_date : {required}
    },

    

    data() {
        return {
            remove_date : '',
            subjectRemoveFile : null,
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 50,
            },
        }
    },

    methods: {
        formSubmit() {
           
            this.$v.$touch();
            
            if (this.$v.$invalid == false) {
                let data = {
                    remove_date   : this.remove_date,
                    order_deal_no : this.deal.order_deal_id,
                    remove_file   : this.subjectRemoveFile
                }
                getDealProcessApi().remove_subject(data).then(res => {
                    if (res.errCode == 0) {
                        this.$alertify.message(" Subject Remove Success");
                        this.$emit('confirm', this.deal)
                    } else {
                        this.$alertify.error(" Subject Remove Failed:" + res.errCode);
                    }
                })
            }
            
        },

        onFileUploaded(ev, resp) {

            if (resp[0].errCode == 200) {
                this.subjectRemoveFile = {
                    save_route: resp[0].response.url,
                    save_route_key: resp[0].response.key,
                    file_name: resp[0].response.filename,
                    size: resp[0].response.size,
                    type: resp[0].response.type
                }
            }
        },

    },

    created() {
      
    },

    mounted() {

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">

                <div class="row">
                    <div class="col-lg-4">
                        <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Remove Date</label>
                            <div class="mb-3">

                            <flat-pickr v-model="remove_date" placeholder="Select a date" class="form-control"
                                :class="{ 'is-invalid': $v.remove_date.$error }"></flat-pickr>
                            <div v-if="$v.remove_date.$error" class="invalid-feedback">
                                <span v-if="!$v.remove_date.required">This value is required.</span>
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mb-3">
                        <vue-dropzone id="dropzone" :use-custom-slot="true" :options="dropzoneOptions"
                          @vdropzone-success="onFileUploaded">
                          <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop doc to here.</h4>
                          </div>
                        </vue-dropzone>
                      </div>

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Confirm</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>