<script>


/**
 * Download Files component
 */


import simplebar from "simplebar-vue";

import { getDealApi } from '@/api/deal/index'

export default {

    emits: ['confirm', 'cancel', 'send'],

    props: ['deal'],

    components: {
        simplebar
    },


    data() {

        return {
            submitted: false,
            files: [],
            profile: require("@/assets/images/users/avatar-2.jpg"),
            choice_obj: {},
            other_reason: '',
            fields: [
                { key: 'CK', label: '' },
                { key: 'FileName' }
            ],
            file_ids: []
        }

    },
    methods: {

        downloadFiles() {
            console.log(this.file_ids);
            let data = {
                deal_id: this.deal.order_deal_id,
                files: this.file_ids
            }
            getDealApi().zip_files(data).then(res => {
                if (res.errCode == 0) {
                    window.open(res.data.url, '_blank');
                } else {
                    this.$alertify.error("Zip files failed " + res.errCode);
                }
            })
        },

        sendFiles() {
            let selected_files = []
            this.file_ids.map(id=>{
                let f = this.files.find(f=>f.id == id)
                selected_files.push(f)
            })
            this.$emit('send', selected_files)
        }


    },

    created() {

    },

    mounted() {

        this.deal.files.map((f) => {
            this.files.push(f)
        })

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body" style="z-index: 99999999" >


            <simplebar class="chat-message-list" >
                <div class="table-responsive">
                    <div class="table align-middle table-nowrap">
                        <b-table :items="files" :fields="fields" responsive="sm" :per-page="100">

                            <template #cell(CK)="data">
                                <input type="checkbox" :value="data.item.id" v-model="file_ids" />
                            </template>
                            <template #cell(FileName)="data">
                                {{ data.item.file_name }}
                            </template>

                        </b-table>
                    </div>
                </div>
                <!-- end table responsive -->
            </simplebar>

            <div class="row ">
              
                <div class="col-sm-12  justify-content-md-center d-flex gap-2 flex-wrap">
              
                    <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                    <b-button variant="primary" type="button" @click="downloadFiles">Download</b-button>
                    <b-button variant="primary" type="button" @click="sendFiles" >Send</b-button>
                </div>
               

            </div>
            <!-- end row-->
        </div>
    </div>
    <!-- end card -->
</template>