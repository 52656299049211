
<script>


/**
 * Transfer Dialog component
 */

 import Choices from "choices.js";

import {getDealProcessApi} from '@/api/deal/process'
import {getCashApi} from '@/api/cash'
import { required } from "vuelidate/lib/validators";

export default {

    emits: ['confirm', 'cancel'],

    props: ['deal'],
    components: {
        
    },

    validations() {
       if (this.is_interests == true) {
        return {
            interests: {required},
            total_amount :  {required},
        }
       } else {
        return {
            interests: {},
            total_amount :  {required},
        }
       }
    },



    data() {
        return {
           
            total_amount : 0,
            description  : '',
            account_list : [],
            remit_bank_id : '',
            receive_bank_id : '',
            is_interests : false,

            remit_bank_choice: {},
            recieve_bank_choice : {},
            interests : '',
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.make_transfer()
            }
        },

        make_transfer() {
            let data = {
                order_deal_no   : this.deal.order_deal_id,
                amount          : Number(this.total_amount),
                remit_bank_id   : this.remit_bank_id, 
                receive_bank_id : this.receive_bank_id,
            }

            if (this.is_interests == true) {
                data.interests = Number(this.interests)
            }

            getDealProcessApi().interest_transfer(data).then(res => {
                if (res.errCode == 0) {
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Make transfer Failed ret: "+ res.errCode);
                }
            })
        }

    },

    created() {
    },

    mounted() {
        this.total_amount        = this.deal.balance
        this.remit_bank_choice   = new Choices('#remit_bank_account_choice')
        this.recieve_bank_choice = new Choices('#receive_bank_account_choice')

        getCashApi().bank_account_list({}).then((res) =>{
            if (res.errCode == 0) {
                res.data.accounts.map((a)=>{
                    //Trust or Commission
                    if (a.is_trust || a.account_type == 'bankAccount_type2') {
                        this.account_list.push({
                            label       : a.name,
                            value       : a.id,
                            account     : a.a_account,
                            account_obj : a,
                            selected    : false
                        })
                        
                    }
                })

                this.remit_bank_choice.setChoices(this.account_list)
                this.recieve_bank_choice.setChoices(this.account_list)
            }

            this.account_list[0].selected = true
            this.receive_bank_id = this.account_list[0].id
            this.remit_bank_id   = this.account_list[0].id
        })
    }



};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>From</label>
                            <select id="remit_bank_account_choice" class="form-control" v-model="remit_bank_id" ></select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="mb-3">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label >To</label>
                            <select id="receive_bank_account_choice" class="form-control" v-model="receive_bank_id"  ></select>
                        </div>
                    </div>
                   
                </div>
                <!-- end row-->

                <div class="row">
                    

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>Amount</label>
                            <input   type="text" class="form-control"  placeholder=""  v-model ="total_amount"  :class="{
                                        'is-invalid': $v.total_amount.$error,
                                    }"/>
                            <div v-if="$v.total_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.total_amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="mb-3">
                            <label for="validationCustom01">Interests </label>
                            <input  v-model="is_interests" type="checkbox" class="form-check"  />
                           
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label>  &nbsp;</label>
                            <input   type="text" class="form-control"  placeholder=""  v-model ="interests" :disabled="!is_interests"  :class="{
                                        'is-invalid': $v.interests.$error,
                                    }"/>
                            <div v-if="$v.interests.$error" class="invalid-feedback">
                                <span v-if="!$v.interests.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    

                    <div class="col-md-10">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input  v-model="description" type="text" class="form-control"  placeholder=""  />
                           
                        </div>
                    </div>
                   
                </div>
                <!-- end row-->

               


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>