<script>


/**
 * Deal Deposit TAB component
 */


import { financial } from '@/api/misc'
import moment from 'moment'

export default {

  emits: ['confirm', 'cancel'],

  props: ['deal'],
  components: {

  },

  data() {

    return {

    }

  },
  computed: {

  },

  methods: {

    f(n, d = 2) {
      return financial(n, d)
    },

   
    get_deposit_status(d) {
      if (d.funds_date) {
        return moment(d.funds_date).format("YYYY-MM-DD")
      } else {
        return 'No'
      }
    }

  },

  created() {


  },

  mounted() {
    
  }

};
</script>

<template>
  <div class="row">

    <div class="table-responsive">
      <div class="table align-middle table-nowrap">
        <b-table  :items="deal.deposits" :fields="['Amount', 'DueDate','FundsDate','File']" responsive="sm" :per-page="deal.deposits.length"
           striped hover>

          <template #cell(Amount)="data">
            ${{ f(data.item.deposit).toLocaleString() }}
          </template>

          <template #cell(DueDate)="data">
            {{ data.item.due_date }}
          </template>

          <template #cell(FundsDate)="data">
            {{ get_deposit_status(data.item)}}
          </template>
       

          <template #cell(File)>
            
          </template>
        </b-table>
      </div>
    </div>
    <!-- end table responsive -->


  </div>
  <!-- end row -->
</template>